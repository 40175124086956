const defaultField = {
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        helpText: String,
        error: String,
        hasError: {
            type: Boolean,
            default: false
        }
    }
}

export default defaultField;
