<template>
  <DefaultField :helpText="helpText"
                :error="error"
  >
    <div class="relative">
      <TransitionIconZoom>
        <div v-if="$slots.icon"
             class="absolute inset-y-0 flex items-center pointer-events-none"
             :class="[
               (icon === 'leading') ? 'left-0 pl-3' : 'right-0 pr-3',
               hasError ? 'text-red-500' : 'text-gray-400'
             ]"
        >
          <slot name="icon"
                :classes="iconClasses"
          />
        </div>
      </TransitionIconZoom>

      <input :type="type"
             ref="input"
             v-bind="$attrs"
             v-mask="mask"
             :value="modelValue"
             :disabled="disabled"
             :spellcheck="spellcheck"
             class="border focus:outline-none block w-full text-base sm:text-sm rounded-md"
             :class="[
               hasError
               ? 'text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500 border-red-300'
               : 'text-gray-900 shadow-sm focus:ring-violet-500 focus:border-violet-500 border-gray-300',
               {'cursor-not-allowed opacity-50' : disabled},
               {'pl-10': $slots.icon && (icon === 'leading')},
               {'pr-10': $slots.icon && (icon === 'trailing')},
             ]"
             @input="$emit('update:modelValue', $event.target.value)"
      >
    </div>
  </DefaultField>
</template>

<script>
import TransitionIconZoom from "@/components/transitions/TransitionIconZoom";
import DefaultField from "@/components/DefaultField";

import defaultField from "@/mixins/defaultField";

export default {
  inheritAttrs: false,
  mixins: [defaultField],
  emits: ['update:modelValue'],
  components: {
    TransitionIconZoom,
    DefaultField
  },
  props: {
    modelValue: String,
    type: {
      type: String,
      default: "text"
    },
    mask: {
      default: "",
      type: String
    },
    spellcheck: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: "leading",
      validator(value) {
        return ["leading", "trailing"].includes(value)
      }
    },
  },
  computed: {
    iconClasses() {
      return "h-5 w-5";
    }
  }
}
</script>
