import app from "@/app";

import AppLoader from "@/components/AppLoader";
import AppLabel from "@/components/AppLabel";
import AppInput from "@/components/AppInput";
import AppModal from "@/components/AppModal";

app
    .component("AppLoader", AppLoader)
    .component("AppLabel", AppLabel)
    .component("AppInput", AppInput)
    .component("AppModal", AppModal)