import app from "@/app";

import store from "@/store";
import router from "@/router";

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

app
    .use(store)
    .use(router)
    .use(Toast)
