import app from "@/app";

// Hack vue mask support for vue3
import {VueMaskDirective} from 'v-mask';

const vMaskV2 = VueMaskDirective;
const vMaskV3 = {
    beforeMount: vMaskV2.bind,
    updated: vMaskV2.componentUpdated,
    unmounted: vMaskV2.unbind
};

app
    .directive("mask", vMaskV3)