<template>
  <slot></slot>
  <p v-if="helpText"
     class="mt-2 text-xs text-gray-500"
  >
    {{ helpText }}
  </p>
  <TransitionInputError>
    <p v-if="error"
       v-text="error"
       class="mt-2 text-xs text-red-600"
    ></p>
  </TransitionInputError>
</template>

<script>
import TransitionInputError from "@/components/transitions/TransitionInputError";

import defaultField from "@/mixins/defaultField";

export default {
  mixins: [defaultField],
  components: {
    TransitionInputError
  }
}
</script>

<style scoped>

</style>