import axios from 'axios'

window.axios = axios.create({
    baseURL: process.env.VUE_APP_TRGUJ_PAY_API
});

import app from "@/app";
import "@/app/directives";
import "@/app/components";
import "@/app/plugins";

app.mount("#app");
