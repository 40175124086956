import { createRouter, createWebHistory } from "vue-router";

import Landing from "@/views/Landing";

const routes = [
    {
        path: "/",
        name: "landing",
        component: Landing,
        meta: { title: "Trguj Pay" }
    },
    {
        path: "/order/:order",
        name: "order",
        component: () => import("@/views/order/Order"),
        meta: { title: "Order" },
        children: [
            {
                path: ":status/:transactionToken",
                name: "status",
                component: () => import("@/views/order/status/Status"),
                meta: { title: "Order Status" }
            }
        ]
    },
    {
        path: "/:pathMatch(.*)*",
        name: "not-found",
        component: () => import("@/views/errors/404"),
        meta: {title: "Not Found"}
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

router.afterEach((to) => {
    const DEFAULT_TITLE = "Trguj.me Checkout";
    document.title = to.meta.title ? `${to.meta.title} | ${DEFAULT_TITLE}` : DEFAULT_TITLE;
});

export default router;
