import OrderService from "@/services/order.service";

import {SET_ORDER, SET_TRANSACTION} from "@/store/types/mutations.type";
import {FETCH_ORDER, FETCH_TRANSACTION} from "@/store/types/actions.type";

const order = {
    namespaced: true,
    state: {
        items: [],
        token: null,
        uid: null,
        urls: {
            success: '',
            error: '',
            cancel: ''
        },
        transaction: {}
    },
    mutations: {
        [SET_ORDER](state, order) {
            Object.assign(state, order);
        },
    },
    actions: {
        [FETCH_ORDER]({commit}, order) {
            return OrderService.get(order)
                .then(({data}) => {
                    const order = data.data;

                    const orderItemDump = {
                        maxQuantity: 5
                    };

                    order.items.forEach(order => Object.assign(order, orderItemDump));

                    return order;
                })
                .then(
                    (order) => {
                        commit(SET_ORDER, order);
                        return Promise.resolve(order);
                    },
                    error => Promise.reject(error)
                );
        },
        [FETCH_TRANSACTION]({commit}, transaction) {
            return OrderService.getTransaction(transaction)
                .then(({data}) => {
                    return data.data;
                })
                .then(
                    (transaction) => {
                        commit(SET_TRANSACTION, transaction);
                        return Promise.resolve(transaction);
                    },
                    error => Promise.rejec(error)
                );
        }
    },
    getters: {
        products(state) {
            return state.items;
        },
        featuredProduct(state) {
            return state.items[0];
        },
        total(state) {
            return state.items.reduce((memo, {price, quantity}) => memo + price * quantity, 0);
        },
        urls(state) {
            return state.urls;
        },
        transaction(state) {
            return state.transaction;
        }
    }
};

export default order;
