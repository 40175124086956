<template>
  <div>
    <!-- Trigger -->
    <span v-if="$slots.trigger"
          @click="open"
    >
        <slot name="trigger"/>
    </span>

    <!-- Modal -->
    <Teleport to="body">
      <TransitionRoot
          :show="show"
          :appear="transitionAppear"
      >
        <div class="fixed inset-0 overflow-y-auto z-50"
             :class="$attrs.class"
        >
          <!-- Overlay -->
          <TransitionChild
              enter="ease-out duration-300"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="ease-in duration-200"
              leave-from="opacity-100"
              leave-to="opacity-0"
              as="template"
              @before-enter="overlayTransitionLeaveFinished = false"
              @after-leave="overlayTransitionLeaveFinished = true"
              :appear="transitionAppear"
          >
            <div @click.self="close"
                 class="fixed inset-0 bg-black bg-opacity-50 transition-opacity"
            ></div>
          </TransitionChild>

          <!-- Panel -->
          <TransitionChild
              :enter="panelTransition['enter']"
              :enter-from="panelTransition['enter-from']"
              :enter-to="panelTransition['enter-to']"
              :leave="panelTransition['leave']"
              :leave-from="panelTransition['leave-from']"
              :leave-to="panelTransition['leave-to']"
              as="template"
              :appear="transitionAppear"
              @before-enter="panelTransitionLeaveFinished = false"
              @after-leave="panelTransitionLeaveFinished = true"
          >
            <div @click.self="close"
                 :class="panelClasses"
            >
              <!-- Card -->
              <div class="relative"
                   :class="[width, bgColor, cardClasses]"
              >
                <h2 v-if="title"
                    class="text-lg leading-6 font-medium text-gray-900"
                    :class="titleAlignClass"
                >
                  {{ title }}
                </h2>

                <slot v-bind="{show, open, close}"></slot>

                <button v-if="showDismiss && dismissible"
                        @click="close"
                        class="absolute top-3 right-3 text-gray-500"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </div>
          </TransitionChild>
        </div>
      </TransitionRoot>
    </Teleport>
  </div>
</template>

<script>
import {TransitionRoot, TransitionChild} from "@headlessui/vue";

export default {
  emits: ["close"],
  components: {
    TransitionRoot,
    TransitionChild
  },
  props: {
    triggerShow: {
      type: Boolean,
      default: false
    },
    dismissible: {
      type: Boolean,
      default: true,
    },
    showDismiss: {
      type: Boolean,
      default: true,
    },
    transitionAppear: {
      type: Boolean,
      default: true,
    },
    title: String,
    titleAlign: {
      type: String,
      default: "center",
      validator(align) {
        return ["left", "center", "right"].includes(align);
      }
    },
    cardClasses: {
      type: String,
      default: "w-full rounded-lg shadow-xl p-8 overflow-y-auto"
    },
    width: {
      type: String,
      default: "max-w-2xl"
    },
    bgColor: {
      type: String,
      default: "bg-white"
    },
    panelClasses: {
      type: String,
      default: "relative min-h-screen flex items-center justify-center p-4 transition-all"
    },
    panelTransition: {
      type: Object,
      default() {
        return {
          "enter": "ease-out duration-300",
          "enter-from": "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95",
          "enter-to": "opacity-100 translate-y-0 sm:scale-100",
          "leave": "ease-in duration-200",
          "leave-from": "opacity-100 translate-y-0 sm:scale-100",
          "leave-to": "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        }
      }
    }
  },
  data() {
    return {
      show: false,
      overlayTransitionLeaveFinished: false,
      panelTransitionLeaveFinished: false,
    }
  },
  watch: {
    "triggerShow": {
      immediate: true,
      handler() {
        this.triggerShow ? this.open() : this.close();
      }
    },
    "transitionLeaveFinished"() {
      if (this.transitionLeaveFinished) this.$emit("close");
    }
  },
  computed: {
    transitionLeaveFinished() {
      return this.overlayTransitionLeaveFinished && this.panelTransitionLeaveFinished;
    },
    titleAlignClass() {
      switch (this.titleAlign) {
        case "left":
          return "text-left"
        case "right":
          return "text-right"
        case "center":
        default:
          return "text-center"
      }
    },
  },
  mounted() {
    document.addEventListener("keydown", (e) => {
      if (e.key === "Escape") this.close();
    });
  },
  methods: {
    open() {
      this.show = true;
    },
    close() {
      if (!this.dismissible) return;

      this.show = false;
    },
  }
}
</script>

<style scoped>

</style>
