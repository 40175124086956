import {createStore} from "vuex";

import order from "@/store/modules/order.module";

const store = createStore({
    modules: {
        order
    }
});

export default store;
