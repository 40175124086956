<template>
  <div class="flex justify-between items-center">
    <label
        v-bind="$attrs"
        class="block text-sm font-medium text-gray-700"
    >
      <slot></slot>
    </label>
    <TransitionLabel>
      <span v-if="required"
            class="text-[0.6875rem] font-semibold text-red-600 uppercase"
      >
        Obavezno
      </span>
    </TransitionLabel>
  </div>
</template>

<script>
import TransitionLabel from "@/components/transitions/TransitionLabel";

export default {
  inheritAttrs: false,
  components: {
    TransitionLabel
  },
  props: {
    required: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>

</style>