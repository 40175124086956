<template>
  <transition enter-active-class="transition-[opacity,transform] duration-300 ease-in"
              enter-from-class="-translate-x-2 opacity-0"
              leave-active-class="transition-opacity duration-200 ease-out"
              leave-to-class="opacity-0"
  >
    <slot></slot>
  </transition>
</template>

<script>
export default {
  name: "ValidationListTransition",
  functional: true
}
</script>

<style scoped>

</style>