class OrderService {
    get(order) {
        return window.axios.get(`order/${order}`);
    }

    getTransaction(transaction) {
        return window.axios.get(`transaction/${transaction}`);
    }
}

export default new OrderService();
